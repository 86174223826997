import "./App.css";
import axios from "axios";
import kineticslogo from "./imgs/logo.png";
import cross from "./imgs/istockphoto-1094376302-170667a.png";
import { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import Alert from "@mui/material/Alert";
import Select from "react-virtualized-select";
import "react-select/dist/react-select.css";
import "react-virtualized-select/styles.css";

import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
  getCountryCallingCode,
} from "react-phone-number-input";
import { width } from "@mui/system";

function App() {
  useEffect(() => {
    loadCountries();
    loadDioceses();
    loadChurches();
  }, []);
  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This is required field! / !هذا الحقل مطلوب
        </div>
      );
    }
  };
  // const [baseUrl, setBaseUrl] = useState("http://localhost:8000/");
  const [baseUrl, setBaseUrl] = useState("https://cocdportal.stmarkos.org/");
  const [name_en, setName_en] = useState("");
  const [name_ar, setName_ar] = useState("");
  const [telNum1, setTelNum1] = useState("");
  const [telNum2, setTelNum2] = useState("");
  const [telNum3, setTelNum3] = useState("");
  const [faxNum, setFaxNum] = useState("");
  const [email, setEmail] = useState("");
  const [bd, setBd] = useState("");
  const [titleSelect, setTitleSelect] = useState("");
  const [churchSelect, setChurchSelect] = useState("");
  const [dioceseSelect, setDioceseSelect] = useState("");
  const [countrySelect, setCountrySelect] = useState("");
  const [titleField, setTitleField] = useState("");
  const [ordination_date_Field, setOrdination_date_Field] = useState("");
  const [ordination_date2_Field, setOrdination_date2_Field] = useState("");
  const [FK_DioceseId, setFK_DioceseId] = useState("");
  const [bishop, setBishopField] = useState("");
  const [bishopric_date, setBishopric_date] = useState("");
  const [CountryCode, setCountryCode] = useState(0);
  const [CountryCode2, setCountryCode2] = useState(0);
  const [CountryCode3, setCountryCode3] = useState(0);

  const [churchesList, setChurchesList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [diocesesList, setDiocesesList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [done, setdone] = useState("");
  const [message, setmessage] = useState("");
  const [disablesubmit, setdisablesubmit] = useState(false);

  //Church details
  const [churchName_en, setChurchName_en] = useState("");
  const [churchName_ar, setChurchName_ar] = useState("");
  const [churchTel_num1, setChurchTel_num1] = useState("");
  const [churchUrl1, setChurchUrl1] = useState("");
  const [churchAddr_city, setChurchAddr_city] = useState("");
  const [churchAddr_street, setChurchAddr_street] = useState("");

  function onChangeNameEn(e) {
    setName_en(e?.target?.value);
  }
  function onChangeNameAr(e) {
    setName_ar(e?.target?.value);
  }
  function onChangeTelNum1(e) {
    const TelNum1 = e;
    setTelNum1(TelNum1);
  }
  function onChangeTelNum2(e) {
    setTelNum2(e);
  }
  function onChangeTelNum3(e) {
    setTelNum3(e);
  }
  function onChangeFax(e) {
    setFaxNum(e?.target?.value);
  }
  function onChangeEmail(e) {
    setEmail(e?.target?.value);
  }
  function onChangeBd(e) {
    setBd(e?.target?.value);
  }
  function handleTitleSelect(e) {
    setTitleSelect(e?.target?.value);
  }
  function handleChurchSelect(e) {
    console.log(e);
    const index = churchesList.map(e => e.id).indexOf(e?.value);
    console.log("e?.target?.value", churchesList[index]);
    if (e?.target?.value === "other") {
      setChurchSelect(0);
      setChurchName_en("");
      setChurchName_ar("");
      setChurchTel_num1("");
      setChurchUrl1("");
      setChurchAddr_city("");
      setChurchAddr_street("");
    } else {
      setChurchSelect(churchesList[index]?.id);
      setChurchName_en(churchesList[index]?.name_en);
      setChurchName_ar(churchesList[index]?.name_ar);
      if (churchesList[index]?.tel_country_code1 && churchesList[index]?.tel_num1) {
        setChurchTel_num1(
          "+" + (churchesList[index]?.tel_country_code1
            ? churchesList[index]?.tel_country_code1
            : "") + (churchesList[index]?.tel_num1
              ? churchesList[index]?.tel_num1
              : "")
        );
      } else { setChurchTel_num1("") }
      setChurchUrl1(churchesList[index]?.url1);
      setChurchAddr_city(churchesList[index]?.addr_city);
      setChurchAddr_street(churchesList[index]?.addr_street);
    }
  }
  function handleCountrySelect(e) {

    setCountrySelect(e?.value);
  }
  const loadDioceses = async () => {
    setLoading(true);
    return axios({
      url: baseUrl + `api/lookups/dioceses`,
      method: "GET",
    })
      .then((res) => {
        // console.log("results FK_DioceseId", res.data);
        let dioceses = Array.from(res.data, (d, index) => ({
          ...d,
          label: (d?.Name_ar?.trim() == null ? "" : d?.Name_ar?.trim()) + "/" + " " + (d?.Name_en?.trim() == null ? "" : d?.Name_en?.trim()),
          value: d.id,
          key: d.id
        }));
        setDiocesesList(dioceses);
        FK_DioceseId(res.data[0].id);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const loadChurches = async () => {
    setLoading(true);
    return axios({
      url: baseUrl + `api/lookups/churches`,
      method: "GET",
    })
      .then((res) => {
        let churches = Array.from(res.data, (church, index) => ({
          ...church,
          label: church?.name_ar?.trim() + "/" + " " + (church?.name_en?.trim() == null ? "" : church?.name_en?.trim()),
          value: church.id,
          key: church.id
        }));
        churches = churches.filter(church => church && church?.name_ar && church?.id)
        console.log("CHURCHES", churches);
        churches.push({
          id: "other",
          label: "اخرى - other",
          value: "other",
          key: "other"
        })
        setChurchesList(churches);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const loadCountries = async () => {
    setLoading(true);
    return axios({
      url: baseUrl + `api/lookups/countries`,
      method: "GET",
    })
      .then((res) => {
        console.log("results countries", res.data);

        let countries = Array.from(res.data, (country, index) => ({
          ...country,
          label: (country?.name_ar ? country?.name_ar : "") + "/" + " " + (country?.name_en ? country?.name_en : ""),
          value: country.id,
          key: country.id
        }));

        setCountriesList(countries);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };


  function handleSubmit(e) {
    setdisablesubmit(true);
    e.preventDefault();
    console.log("countrySelect", countrySelect);
    axios
      .post(baseUrl + "api/priest", {
        name_en: name_en,
        name_ar: name_ar,
        tel_num1: telNum1,
        tel_num2: telNum2,
        tel_num3: telNum3,
        fax: faxNum,
        email: email,
        birth_date: bd,
        title_en: titleSelect === "other" ? titleField : titleSelect,
        diocese_id: dioceseSelect,
        country_id: countrySelect,
        churches: [churchSelect],
        church_details: {
          name_en: churchName_en,
          name_ar: churchName_ar,
          tel_num1: churchTel_num1,
          url1: churchUrl1,
          addr_city: churchAddr_city,
          addr_street: churchAddr_street,
        },
        ordination_date: ordination_date_Field,
        ordination_date2: ordination_date2_Field,
        monastic_date: bishop,
        bishopric_date: bishopric_date,
      })
      .then((response) => {
        console.log("results", response);
        setdone("Data added Successfully");
        setmessage("");
        e.preventDefault();
        setName_en("");
        setName_ar("");
        setTelNum1("");
        setTelNum2("");
        setTelNum3("");
        setFaxNum("");
        setEmail("");
        setBd("");
        setTitleSelect("");
        setChurchSelect("");
        setDioceseSelect("");
        setCountrySelect("");
        setTitleField("");
        setOrdination_date_Field("");
        setOrdination_date2_Field("");
        setFK_DioceseId("");
        setBishopField("");
        setBishopric_date("");
        setCountryCode(0);
        setCountryCode2(0);
        setCountryCode3(0);
        setTimeout(function () { window.location.reload(); }, 2000);
        setdisablesubmit(false);
      })
      .catch((error) => {
        console.log("errorr here", error);
        setdone("");
        setmessage(
          "Sorry, error ocured, please try again later. خطا! حاول مرة اخري" +
          error
        );
        setdisablesubmit(false);
      });
  }
  return (
    <div className="App">
      <div className="mcontainer">
        {/* <div className="login">
            <a href="#"><img src="../public/imgs/172626_user_male_icon.svg" alt="" />Login</a>
        </div> */}
        <div className="logo">
          <img src={cross} width="200px" alt="" />
        </div>
        <div className="form">
          <form onSubmit={handleSubmit}>
            {/* الاسم كامل */}
            <div className="field">
              <label htmlFor="nameen">Full Name/الاسم كامل</label>
              <p>(English/الإنجليزية)</p>
              <input
                type="text"
                id="nameen"
                onChange={onChangeNameEn}
                name="nameen"
                placeholder="Full Name/الاسم كامل"
                required
              />
            </div>

            {/* Arabic/العربية */}
            <div className="field">
              <label htmlFor="namear">Full Name/الاسم كامل</label>
              <p>(Arabic/العربية)</p>
              <input
                type="text"
                id="namear"
                onChange={onChangeNameAr}
                name="namear"
                placeholder="Full Name/الاسم كامل"
                required
              />
            </div>

            {/* رقم الهاتف */}
            <div className="field">
              <label htmlFor="TelNum1">Mobile/رقم الهاتف</label>
              <p>{telNum1 ? telNum1 : ""}</p>
              <PhoneInput
                required
                placeholder={"Enter phone number 1 ex:" + CountryCode}
                value={telNum1}
                name="TelNum1"
                onCountryChange={(contry) => {
                  setCountryCode(getCountryCallingCode(contry));
                }}
                // internationalIcon={false}
                defaultCountry="EG"
                onChange={onChangeTelNum1}
                validations={[required]}
                limitMaxLength={true}
              />
            </div>

            {/* Number 2 */}
            <div className="field">
              <label htmlFor="TelNum2">Phone Number 2</label>
              <p>(optional/اختيارى) {telNum2 ? telNum2 : ""}</p>
              <PhoneInput
                placeholder={"Enter phone number 2 ex:" + CountryCode2}
                value={telNum2}
                name="TelNum2"
                onCountryChange={(contry) => {
                  setCountryCode2(getCountryCallingCode(contry));
                }}
                // internationalIcon={false}
                defaultCountry="EG"
                onChange={onChangeTelNum2}
                // validations={[required]}
                limitMaxLength={true}
              />
            </div>

            {/* Phone Number 3 */}
            <div className="field">
              <label htmlFor="TelNum3">Home Phone Number</label>
              <p>(optional/اختيارى){telNum3 ? telNum3 : ""}</p>
              <PhoneInput
                placeholder={"Enter phone number 3 ex:" + CountryCode3}
                value={telNum3}
                name="TelNum3"
                onCountryChange={(contry) => {
                  setCountryCode3(getCountryCallingCode(contry));
                }}
                // internationalIcon={false}
                defaultCountry="EG"
                onChange={onChangeTelNum3}
                // validations={[required]}
                limitMaxLength={true}
              />
            </div>

            {/* Fax */}
            <div className="field">
              <label htmlFor="fax">Fax</label>
              <p>(optional/اختيارى)</p>

              <input
                type="text"
                id="fax"
                onChange={onChangeFax}
                name="fax"
                placeholder="01228332102"
              />
            </div>

            {/* البريد الالكترونى */}
            <div className="field">
              <label htmlFor="email">E-Mail/البريد الالكترونى</label>
              <p>(optional/اختيارى)</p>
              <input
                type="email"
                id="email"
                name="email"
                onChange={onChangeEmail}
                placeholder="example@example.com"
              />
            </div>

            {/* تاريخ الميلاد */}
            <div className="field">
              <label htmlFor="birthdate">Birthdate/تاريخ الميلاد</label>
              <p>(optional/اختيارى)</p>
              <input
                type="date"
                id="birthdate"
                // required
                onChange={onChangeBd}
                name="birthdate"
              />
            </div>

            {/* Diocese/الابرشية */}
            <div className="field">
              <label htmlFor="diocese">Diocese/الابرشية</label>
              <Select required id="Diocese" name="Diocese" onChange={(e) => setDioceseSelect(e?.value)} value={dioceseSelect} options={diocesesList} />

              {/* <select
                id="diocese"
                onChange={(e) => setDioceseSelect(e?.target?.value)}
                name="diocese"
              >
                <option value="" key="Select" disabled defaultValue selected>
                  Select Diocese
                </option>
                {diocesesList.map((diocese, idx) => (
                  <>
                    {diocese && diocese.Name_en && (
                      <option key={diocese.id} value={diocese.id}>
                        {diocese.Name_en}
                      </option>
                    )}
                  </>
                ))}
              </select> */}
            </div>

            {/* Country/الدولة */}
            <div className="field">
              <label htmlFor="country">Country/الدولة</label>
              <Select required id="country" name="country" onChange={handleCountrySelect} value={countrySelect} options={countriesList} />
              {/* <select
                id="country"
                onChange={handleCountrySelect}
                name="country"

                // required
              >
                <option value="" key="Select" disabled defaultValue selected>
                  Select Country
                </option>
                {countriesList.map((country, idx) => {
                  return (
                    <>
                      {country && country?.name_en && country.id && (
                        <option key={country.id} value={country.id}>
                          {country?.name_ar ? country?.name_ar : ""} /{" "}
                          {country?.name_en ? country?.name_en : ""}
                        </option>
                      )}
                    </>
                  );
                })}
              </select> */}
            </div>
            {/* Title/لقب */}
            <div className="field">
              <label htmlFor="title">Title/لقب</label>
              <select
                required
                id="title"
                name="title"
                onChange={handleTitleSelect}
              // required
              >
                <option
                  value=""
                  disabled
                  selected
                >
                  Select Title
                </option>
                <option value="priest">كاهن - قس priest</option>
                <option value="higomenos">كاهن - قمص higomenos</option>
                <option value="bishop">اسقف bishop</option>
                <option value="archbishop">مطران archbishop</option>
                <option value="financialmanager">
                  مدير مالي financial manager
                </option>
                <option value="treasurer">امين صندوق treasurer</option>
                <option value="secertary">سكرتارية Secertary</option>
                <option value="boardmember">عضو مجلس board member</option>
                <option value="other">اخرى - other</option>
              </select>
            </div>
            {titleSelect === "other" && (
              // Title/لقب
              <div className="field">
                <label>Title/لقب</label>
                <input
                  onChange={(e) => setTitleField(e?.target?.value)}
                  value={titleField}
                  className="other-field"
                  type="text"
                  required={titleSelect === "other"}
                />
              </div>
            )}

            {titleSelect === "priest" && (
              <div className="field">
                <label>Ordination Date</label>
                <p>(optional/اختيارى)</p>
                <input
                  onChange={(e) => setOrdination_date_Field(e?.target?.value)}
                  value={ordination_date_Field}
                  className="other-field"
                  type="date"
                // required={
                //   titleSelect === "priest" || titleSelect === "higomenos"
                // }
                />
              </div>
            )}
            {titleSelect === "higomenos" && (
              <>
                <div className="field"></div>
                <div className="field">
                  <label>Ordination Date</label>
                  <p>(optional/اختيارى)</p>
                  <input
                    onChange={(e) => setOrdination_date_Field(e?.target?.value)}
                    value={ordination_date_Field}
                    className="other-field"
                    type="date"
                  // required={
                  //   titleSelect === "priest" || titleSelect === "higomenos"
                  // }
                  />
                </div>
                <div className="field">
                  <label>Higomenos promotion date/تاريخ القمصية</label>
                  <p>(optional/اختيارى)</p>
                  <input
                    onChange={(e) =>
                      setOrdination_date2_Field(e?.target?.value)
                    }
                    value={ordination_date2_Field}
                    className="other-field"
                    type="date"
                  // required={titleSelect === "higomenos"}
                  />
                </div>
              </>
            )}

            {titleSelect === "bishop" && (
              <div className="field">
                <label>Bishop Ordination Date/ المطران و الاسقف التاريخ </label>
                <p>(optional/اختيارى)</p>
                <input
                  onChange={(e) => setBishopField(e?.target?.value)}
                  value={bishop}
                  className="other-field"
                  type="date"
                // required={titleSelect === "bishop"}
                />
              </div>
            )}
            {titleSelect === "archbishop" && (
              <div className="field">
                <label>Bishop Ordination Date/ المطران و الاسقف التاريخ </label>
                <p>(optional/اختيارى)</p>
                <input
                  onChange={(e) => setBishopric_date(e?.target?.value)}
                  value={bishopric_date}
                  className="other-field"
                  type="date"
                // required={titleSelect === "archbishop"}
                />
              </div>
            )}

            {/* Church/الكنيسة */}
            <div className="field">
              <label htmlFor="church">Church/الكنيسة</label>
              <Select required id="church" name="church" defaultValue="" onChange={handleChurchSelect} value={churchSelect} options={churchesList} />

              {/* <select
                // required
                id="church"
                name="church"
                onChange={handleChurchSelect}
              >
                <option value="" key="Select" disabled defaultValue selected>
                  Select Church
                </option>
                {churchesList.map((church, idx) => (
                  <>
                    {church && church?.name_ar && church?.id && (
                      <option key={church?.id} value={idx}>
                        {church?.name_ar ? church?.name_ar : ""} /{" "}
                        {church?.name_en ? church?.name_en : ""}
                      </option>
                    )}
                  </>
                ))}
                <option value="other">اخرى - other</option>
              </select> */}
            </div>

            {/* Church/الكنيسة */}
            {churchSelect !== "" && (
              <>
                {/* Church English Name */}
                <div className="field">
                  <label>Church English Name/ اسم الكنيسة بالانجليزي</label>
                  <input
                    onChange={(e) => setChurchName_en(e?.target?.value)}
                    required
                    // required={churchSelect != "other"}
                    className="other-field"
                    type="text"
                    value={churchName_en}
                  />
                </div>
                {/* Church Arabic Name */}
                <div className="field">
                  <label>Church Arabic Name/ اسم الكنيسة بالعربي</label>
                  <input
                    onChange={(e) => setChurchName_ar(e?.target?.value)}
                    required
                    // required={churchSelect != "other"}
                    className="other-field"
                    type="text"
                    value={churchName_ar}
                  />
                </div>
                {/* Church Tel. Number */}
                <div className="field">
                  <label>Church Tel. Number/ رقم تلفون الكنيسة</label>
                  <p>(optional/اختيارى) {churchTel_num1 ? churchTel_num1 : ""}</p>
                  <PhoneInput
                    placeholder={"Enter Church phone number ex:" + CountryCode}
                    value={churchTel_num1}
                    name="churchTelNum1"
                    onCountryChange={(contry) => {
                      setCountryCode(getCountryCallingCode(contry));
                    }}
                    // internationalIcon={false}
                    defaultCountry="EG"
                    onChange={(e) => setChurchTel_num1(e)}
                    // validations={[required]}
                    limitMaxLength={true}
                  />
                </div>
                {/* Church Website */}
                <div className="field">
                  <label>Church Website / موقع الكنيسة</label>
                  <p>(optional/اختيارى)</p>
                  <input
                    onChange={(e) => setChurchUrl1(e?.target?.value)}
                    // required={churchSelect != "other"}
                    className="other-field"
                    type="text"
                    value={churchUrl1}
                  />
                </div>
                {/* Church City */}
                <div className="field">
                  <label>Church City / مدينة الكنيسة</label>
                  <input
                    onChange={(e) => setChurchAddr_city(e?.target?.value)}
                    required
                    // required={churchSelect != "other"}
                    className="other-field"
                    type="text"
                    value={churchAddr_city}
                  />
                </div>
                {/* Church Address */}
                <div className="field">
                  <label>Church Address / عنوان الكنيسة</label>
                  <p>(optional/اختيارى)</p>
                  <input
                    onChange={(e) => setChurchAddr_street(e?.target?.value)}
                    // required={churchSelect != "other"}
                    className="other-field"
                    type="text"
                    value={churchAddr_street}
                  />
                </div>
              </>
            )}

            <div className="submit">
              <input type="submit" disabled={disablesubmit}></input>
            </div>
            {console.log("done", done)}
            {done && <Alert severity="success">{done}</Alert>}
            {console.log("message", message)}

            {message && (
              <div className="form-group">
                <Alert severity="error" align="center" style={{}}>
                  {message}
                </Alert>
              </div>
            )}
          </form>
        </div>
        <div className="bglogo">
          <img src={cross} alt="" />
        </div>
      </div>

      {/* <div id="footer">
        <div className="socket">
          <p>
            All rights are reserved to Kinetics © Powered by{" "}
            <img src={kineticslogo} alt="" />
          </p>
        </div>
      </div> */}
    </div>
  );
}

export default App;
